import React from "react"
import get from "lodash/get"
import { motion } from "framer-motion"
import { useStaticQuery, graphql } from "gatsby"

import { Link } from "@elements"
import { useHover } from "@hooks"

export const DesktopNavigation = () => {
  const data = useStaticQuery(graphql`
    query {
      ...MainNav
      site {
        siteMetadata {
          API_URL
        }
      }
    }
  `)

  //  safely get the main menu or an empty array
  const mainMenu = get(data, "mainNav.menuItems.edges", [])
  const API_URL = get(data, "site.siteMetadata.API_URL", [])

  return (
    <nav className="hidden lg:flex">
      <ul className="flex">
        {mainMenu.map(({ node }, idx) => (
          <MenuItem
            key={node.id}
            node={node}
            isLastMenuItem={mainMenu.length === idx + 1}
            API_URL={API_URL}
          />
        ))}
      </ul>
    </nav>
  )
}

function MenuItem({ node, isLastMenuItem, API_URL }) {
  const [isHovered, bind] = useHover()

  const variants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1 },
  }

  return (
    <li className="relative flex items-stretch justify-center" {...bind}>
      <Link
        to={`${node.url.replace(API_URL, "")}`}
        // add different classes for last item
        className={
          isLastMenuItem
            ? `relative flex items-center justify-center transition duration-150 w-48 ml-6 cursor-pointer font-semibold text-white bg-teal-500 hover:bg-teal-600`
            : `relative flex items-center justify-center transition duration-150 w-40 cursor-pointer hover:text-teal-500`
        }
        activeClassName={isLastMenuItem ? `` : `navigation__link--active`}
        partiallyActive={true}
      >
        {node.label}
      </Link>

      {node.childItems &&
        node.childItems.edges &&
        node.childItems.edges.length > 1 && (
          <motion.ul
            className={`bg-white absolute flex flex-col shadow-xl py-4 px-4 ${
              isHovered ? "" : "hidden"
            }`}
            initial="hidden"
            animate={`${isHovered ? "visible" : "hidden"}`}
            variants={variants}
            style={{
              top: 90,
              left: 20,
              width: 300,
            }}
          >
            {node.childItems.edges.map(({ node }) => {
              return (
                <li key={node.id} className="flex-1 p-4">
                  <Link
                    className="relative flex items-center transition duration-150 cursor-pointer hover:text-teal-500"
                    to={`${node.url.replace(API_URL, "")}`}
                    activeClassName="text-teal-500"
                    partiallyActive={true}
                  >
                    {node.label}
                  </Link>
                </li>
              )
            })}
          </motion.ul>
        )}
    </li>
  )
}
