import React from "react"
import { Link } from "@elements"
import Pattern from "@images/pattern.jpg"
import Logo from "@images/logos/logo-reversed.png"
import EHO from "@images/logos/Equal_Housing_Opportunity.png"
import { useStaticQuery, graphql } from "gatsby"
import chunk from "lodash/chunk"

export const Footer = () => {
  const data = useStaticQuery(graphql`
    query {
      ...FooterSectionOne
      ...FooterSectionTwo
      ...FooterSectionThree
      site {
        siteMetadata {
          API_URL
        }
      }
    }
  `)
  const API_URL = data.site.siteMetadata.API_URL

  const { nav1, nav2, nav3 } = data

  return (
    <footer className="">
      <section
        className="px-4 py-8 text-white bg-left-top bg-repeat bg-cover xl:py-16 lg:py-12"
        style={{ backgroundImage: `url(${Pattern})` }}
      >
        <div className="flex flex-wrap xl:max-w-6xl xl:mx-auto">
          {/*// todo: come fix this */}
          <div className="w-full lg:w-1/4">
            <Link to="/" style={{ filter: `brightness(10)` }}>
              <img
                src={Logo}
                alt="Royal United Mortgage logo"
                className="w-48 md:w-56"
              />
            </Link>
            <div className="text-sm" style={{marginTop: "2.5rem"}}>
              10194 Crosspoint Boulevard<br />
              Suite 110<br />
              Indianapolis, IN 46256
            </div>
          </div>
          <div className="flex flex-wrap w-full -mx-4 lg:w-3/4 md:mt-8 lg:mt-0 lg:justify-between lg:pl-8 xl:pl-0">
            <div className="w-full px-4 mt-6 capitalize md:mt-0 sm:w-1/2 md:w-1/3 lg:w-1/2">
              {nav1.menuItems.nodes.map(item => {
                return (
                  <React.Fragment key={item.id}>
                    <h2 className="text-lg font-semibold leading-none">
                      <Link
                        className="block transition duration-150 hover:text-yellow-400"
                        title={item.title}
                        target={item.target}
                        rel={
                          item.target === "_blank" ? "noopener noreferrer" : ""
                        }
                        to={item.url.replace(API_URL, "")}
                      >
                        {item.label}
                      </Link>
                    </h2>

                    <nav className="flex flex-wrap mt-4 -mr-4 text-sm xl:mt-8">
                      {chunk(item.childItems.nodes, 3).map(group => {
                        return (
                          <div
                            key={group[0].id}
                            className="w-1/2 pr-4 space-y-2 xl:space-y-4"
                          >
                            {group.map(child => {
                              return (
                                <Link
                                  key={child.id}
                                  title={child.title}
                                  target={child.target}
                                  rel={
                                    child.target === "_blank"
                                      ? "noopener noreferrer"
                                      : ""
                                  }
                                  className="block transition duration-150 hover:text-yellow-400"
                                  to={child.url.replace(API_URL, "")}
                                >
                                  {child.label}
                                </Link>
                              )
                            })}
                          </div>
                        )
                      })}
                    </nav>
                  </React.Fragment>
                )
              })}
            </div>
            <div className="w-full px-4 mt-8 capitalize md:mt-0 sm:w-1/2 md:w-1/3 lg:w-1/4">
              {nav2.menuItems.nodes.map(item => {
                return (
                  <React.Fragment key={item.id}>
                    <h2 className="text-lg font-semibold leading-none">
                      <Link
                        className="block transition duration-150 hover:text-yellow-400"
                        title={item.title}
                        target={item.target}
                        rel={
                          item.target === "_blank" ? "noopener noreferrer" : ""
                        }
                        to={item.url.replace(API_URL, "")}
                      >
                        {item.label}
                      </Link>
                    </h2>
                    <nav className="flex flex-wrap mt-4 -mr-4 text-sm xl:mt-8">
                      {chunk(item.childItems.nodes, 2).map((group, idx) => {
                        return (
                          <div
                            key={group[0].id}
                            className={`w-1/2 pr-4 space-y-2 xl:space-y-4 md:w-full ${
                              idx > 0 ? "md:mt-2 xl:mt-4" : ""
                            }`}
                          >
                            {group.map(child => {
                              return (
                                <Link
                                  key={child.id}
                                  title={child.title}
                                  target={child.target}
                                  rel={
                                    child.target === "_blank"
                                      ? "noopener noreferrer"
                                      : ""
                                  }
                                  className="block transition duration-150 hover:text-yellow-400"
                                  to={child.url.replace(API_URL, "")}
                                >
                                  {child.label}
                                </Link>
                              )
                            })}
                          </div>
                        )
                      })}
                    </nav>
                  </React.Fragment>
                )
              })}
            </div>
            <div className="w-full px-4 mt-6 capitalize md:mt-0 md:pl-4 lg:pl-0 sm:mt-8 md:w-1/3 lg:w-1/4">
              <nav className="space-y-4 xl:space-y-8 sm:flex sm:space-y-0 sm:justify-between sm:max-w-md md:max-w-none md:block md:space-y-4">
                {nav3.menuItems.nodes.map(item => {
                  return (
                    <Link
                      key={item.id}
                      className="block text-lg font-semibold leading-none transition duration-150 hover:text-yellow-400"
                      title={item.title}
                      target={item.target}
                      rel={
                        item.target === "_blank" ? "noopener noreferrer" : ""
                      }
                      to={item.url.replace(API_URL, "")}
                    >
                      {item.label}
                    </Link>
                  )
                })}
              </nav>
            </div>
          </div>
        </div>
      </section>
      <section className="flex items-center justify-between px-8 py-4 text-white bg-blue-400">
        <small className="text-sm">&copy; All Rights Reserved</small>
        <img src={EHO} alt="Equal Housing Lender" className="" />
        <small className="text-base font-semibold">NMLS# 13390</small>
      </section>
      <div className="ie-warning-bar px-4 py-4 lg:px-10 text-center bg-red-700 z-20 announcement-bar">
        <p>
          <strong>WARNING:</strong> Your browser is not fully supported. Some
          images, layouts, and functionality may not operate as expected due to
          the age of your browser. Please upgrade or use an alternate browser.
        </p>
      </div>
    </footer>
  )
}
